import { useParams } from '@solidjs/router';
import { For, Show, createSignal, onMount } from 'solid-js';
import { SUBMIT_ANSWERS } from '~/assets/strings';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import { Button } from '~/components/common/buttons';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { GetRentalApplicationStatusUseCase } from '~/use-cases/rental-applications/application-steps/getRentalApplicationStatusUseCase';
import { SendRentalApplicationScreeningAnswersUseCase } from '~/use-cases/rental-applications/screening/sendRentalApplicationScreeningAnswersUseCase';

const TransUnionQuestionnaire = () => {
  const { t } = useLocalization();
  const params = useParams();

  type SelectedAnswersType = Record<string, string>;
  const [selectedAnswers, setSelectedAnswers] = createSignal<SelectedAnswersType>({});
  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: getRentalApplicationStatus, isLoading: isLoadingApplication } = useUseCase(GetRentalApplicationStatusUseCase);
  const { execute: postAnswers, isLoading: isPostingAnswers } = useUseCase(SendRentalApplicationScreeningAnswersUseCase);

  onMount(async () => {
    getRentalApplicationStatus({ id: params.id, password: params.password });
    getRentalApplicationStatus({ id: params.id, password: params.password });
  });

  const updateAnswer = (questionKey: string, choiceKey?: string) => {
    if (questionKey && choiceKey) {
      setSelectedAnswers((prev) => ({ ...prev, [questionKey]: choiceKey }));
    }
  };

  const handleSubmit = async () => {
    const answers = Object.entries(selectedAnswers()).map(([questionKeyName, selectedChoiceKeyName]) => ({
      questionKeyName,
      selectedChoiceKeyName,
    }));
    postAnswers(answers);
  };

  return (
    <Show
      when={!isLoadingApplication() && !isPostingAnswers()}
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <div class="scrollbar flex flex-col overflow-y-auto bg-white p-5">
        <For each={presentableRentalApplication()?.transUnionScreening?.questions}>
          {(question) => (
            <div class="mb-4 flex w-full flex-col">
              <div class="text-lg font-semibold">{question.questionDisplayName}</div>
              <div class="flex flex-col">
                <For each={question.choices}>
                  {(choice) => (
                    <div class="flex p-2">
                      <input
                        type="radio"
                        name={question.questionKeyName ?? ''}
                        value={choice.choiceKeyName ?? ''}
                        checked={selectedAnswers()[question.questionKeyName] === choice.choiceKeyName}
                        onChange={() => updateAnswer(question.questionKeyName, choice.choiceKeyName)}
                      />
                      <div class="ml-1">{choice.choiceDisplayName}</div>
                    </div>
                  )}
                </For>
              </div>
            </div>
          )}
        </For>
      </div>
      <div class="sticky bottom-0 left-0 w-full border-t border-gray-200 bg-white p-4">
        <div class="flex items-end justify-end">
          <Button onClick={handleSubmit}>{t(SUBMIT_ANSWERS)}</Button>
        </div>
      </div>
    </Show>
  );
};

export default TransUnionQuestionnaire;
